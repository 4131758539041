import React, { useState, useEffect } from "react";
import styles from "./buyBeasts.module.scss";

const BuyBeasts = () => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setIsMobile(e.matches));
  }, []);

  return (
    <div className={styles.cover}>
      <p className={styles.capitals}>BUY BITCOIN BEASTS</p>
      <p className={styles.title}>
        Buy 1 of 21 Limited Edition Bitcoin Beast Plushies.
      </p>
      <div className={styles.column}>
        {isMobile ? (
          <img
            src="/bitcoin_beast_plushies_sm.png"
            alt="World map"
            className={styles.image}
          />
        ) : (
          <img
            src="/bitcoin_beast_plushies.png"
            alt="World map"
            className={styles.image}
          />
        )}
        {isMobile && (
          <p className={styles.subtitle}>
            Buy a limited edition Bitcoin-themed Beast plushy from Jippi's
            upcoming Bitcoin education game Tribe Clash. Our Bitcoin Beasts are
            hand-made and are shipped to any US-based address.
          </p>
        )}
      </div>
      <button
        className={styles.navButton}
        onClick={() =>
          window.open("https://pay.zaprite.com/pl_wZnBNNQ7wQ", "_blank")
        }
      >
        Buy a Plushy
      </button>
    </div>
  );
};

export default BuyBeasts;
